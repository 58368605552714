import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enJSON from './locale/en.json'
import jpJSON from './locale/jp.json'
import zhCNJSON from './locale/zh-CN.json'
import zhTWJSON from './locale/zh-TW.json'

i18n.use(initReactI18next).init({
    resources: {
        en: { ...enJSON },
        ja: { ...jpJSON },
        zh_cn: { ...zhCNJSON },
        zh: { ...zhTWJSON },
    },
    lng: "en",
    fallbackLng: "en",
    debug: false,
    interpolation: {
        escapeValue: false
    }
});