import { BrowserRouter, Routes, Route } from 'react-router-dom'
import SpaceOrderCalendar from './component/SpaceOrderCalendar';
import ScheduleTimeline from './component/ScheduleTimeline';
import './App.css';

function App(props) {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/time/:token/" element={<SpaceOrderCalendar />} />
        <Route path="/3DSpace/:token/" element={<ScheduleTimeline />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;